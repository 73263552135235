import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { useMitt } from "react-mitt"
import { motion } from "framer-motion"
import { isMobile } from "react-device-detect"
import {
  indexScreenNames,
  nextScreenLabels,
  nextScreenLabels_mobile,
} from "_src/assets/data/global_variables"
import {
  color_paragraphHighlite,
  color_primButton,
  color_pageTitle,
} from "_src/assets/data/global_colors"

const spring = {
  type: "spring",
  stiffness: 530,
  damping: 60,
  mass: 5,
  restDelta: 0.001,
  restSpeed: 0.001,
}

const BreadCrumbs = (props) => {
  const { emitter } = useMitt()
  const [hoveredCrumb, hoveredCrumb_set] = useState()
  const nextLabel = useRef(nextScreenLabels)

  useEffect(() => {
    if (isMobile) nextLabel.current = nextScreenLabels_mobile
    hoveredCrumb_set(nextLabel.current[props.current])
  }, [props])

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <motion.div
        style={{
          opacity: props.header_opacity,
          zIndex: 1500,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* style={{ opacity: labelOpacity }} */}
        <Label
          style={{ perspective: "500px" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2, duration: 2, ease: "easeOut" }}
        >
          <motion.div
            transition={spring}
            whileHover={{
              scale: 1.05,
            }}
            onClick={function () {
              emitter.emit("pageChange", {
                data: props.current + 1,
                currentScreen: props.current,
              })
            }}
            // style={{ perspective: "500px" }}
          >
            {hoveredCrumb}
          </motion.div>
        </Label>
        <DotWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2, duration: 2, ease: "easeOut" }}
        >
          {indexScreenNames.map((name, i) => (
            <Dot
              key={i}
              onMouseOver={() => {
                // labelOpacity.set(1)
                hoveredCrumb_set(indexScreenNames[i])
              }}
              onMouseLeave={() => {
                // labelOpacity.set(0)
                hoveredCrumb_set(nextLabel.current[props.current])
              }}
              onClick={function () {
                emitter.emit("pageChange", {
                  data: i,
                  currentScreen: props.current,
                })
              }}
              whileHover={{ scale: 1.5, backgroundColor: color_primButton }}
              style={{
                backgroundColor:
                  props.current === i
                    ? color_pageTitle
                    : color_paragraphHighlite,
              }}
            />
          ))}
        </DotWrapper>
      </motion.div>
    </div>
  )
}

const DotWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 150px;
`

const Dot = styled(motion.div)`
  height: 20px;
  width: 20px;
  border-radius: 5px;
  cursor: pointer;
`

const Label = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 300px;
  font-family: montserrat;
  font-size: 0.8em;
  font-weight: 800;
  color: ${color_paragraphHighlite};
  cursor: pointer;
`

export default BreadCrumbs
