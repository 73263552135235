import React from "react"
import { motion } from "framer-motion"
import styled from "styled-components"

const Mouse = () => {
  return (
    <Position
      transition={{
        delay: 1.75,
        duration: 1.7,
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <Body>
        <motion.div
          animate={{
            translateY: [-2, 2],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
          }}
        >
          <motion.div
            animate={{
              opacity: [0, 1, 0],
              times: [0, 1, 2],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
            }}
          >
            <Wheel />
          </motion.div>
        </motion.div>
      </Body>
    </Position>
  )
}

const Position = styled(motion.div)`
  position: fixed;
  margin-top: 30px;
  margin-left: 30px;
  width: 45px;
  height: 67px;
  z-index: 5;
  transform: scale(0.9);
`

const Body = styled(motion.div)`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #174b73;
  box-shadow: 1px 1px 8px -3px rgba(0, 0, 0, 0.5);
`

const Wheel = styled(motion.div)`
  width: 8px;
  height: 15px;
  border-radius: 10px;
  margin-bottom: 25px;
  margin-left: 1px;
  background-color: white;
`

export default Mouse
