import React from "react"
import styled from "styled-components"
import { isMobile } from "react-device-detect"
import { motion } from "framer-motion"

const textVariant = {
  open: {
    opacity: 1,
    translateY: 0,
    transition: {
      delay: 0.1,
      duration: 0.5,
    },
  },
  closed: {
    opacity: 0,
    translateY: 5,
    transition: {
      duration: 0.15,
    },
  },
}

const Instructions = (props) => {
  var text = "Use your mouse wheel to navigate between pages."
  if (isMobile) text = "Swipe to navigate between pages."

  return (
    <Position style={{ display: props.help_isActive ? "inline" : "none" }}>
      <Copy
        variants={textVariant}
        initial={{ opacity: 0 }}
        animate={props.help_isActive ? "open" : "closed"}
      >
        {text}
      </Copy>
    </Position>
  )
}

const Position = styled.div`
  position: absolute;
  z-index: 30;
  overflow: hidden;
  right: 15px;
  bottom: 0px;
  height: 95px;
  width: 135px;
`

const Copy = styled(motion.div)`
  font-family: montserrat;
  font-size: 0.8em;
  line-height: 1.4em;
  color: white;
  font-weight: 600;
`

export default Instructions
