import React, { useEffect, useRef, useState, useLayoutEffect } from "react"
import { isMobile, isFirefox } from "react-device-detect"
import { indexScreenUrls } from "_src/assets/data/global_variables"
import { Logger, NewCookieExpiryDate } from "_data/global_functions.js"
import { motion, useMotionValue, useAnimation } from "framer-motion"
import BreadCrumbs from "_components/indexScreens/common/bottomNav/breadCrumbs"
import Corner from "_components/indexScreens/common/bottomNav/corner/_corner"
import { useCookies } from "react-cookie"
import styled from "styled-components"
import Header from "_components/nav/header/_header"
import { navigate } from "gatsby"
import Footer from "_components/nav/footer/_footer"
import { useWindowHeight } from "@react-hook/window-size"
import useResizeObserver from "@react-hook/resize-observer"
import { useMitt } from "react-mitt"
import { useWheel } from "react-use-gesture"
import { useScrollPosition } from "@huse/scroll-position"

const useSize = (target) => {
  const [size, setSize] = useState()

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect())
  }, [target])

  useResizeObserver(target, (entry) => setSize(entry.contentRect))
  return size
}

export function ScreensLayout(
  Content,
  screenSwipeBackgroundColor,
  screenBackgroundColor,
  uprContentMarg,
  lwrContentMarg,
  showFooter,
  nextPageAllowed,
  previousPageAllowed,
  currentScreen
) {
  const windowHeight = useWindowHeight()
  const [allowScrollBars, setAllowScrollBars] = useState(false)
  const [viewPortHeight, setViewPortHeight] = useState()
  const [contentHeight, setContentHeight] = useState()
  const [dragContraint, setDragContraint] = useState(0)
  const [showCorner, setShowCorner] = useState(true)
  const [cookies, setCookie] = useCookies(["userSwipedPage"])
  const { emitter } = useMitt()
  const wheel_swipeVelocity_firefox = 3.25
  const wheel_swipeVelocity = 4.25
  const navigateTo = useRef(0)
  const animTo = useAnimation()
  const y = useMotionValue(0)
  const ref = useRef(null)
  const scrollPosition = useScrollPosition(ref.current)
  const target = useRef(null)
  const size = useSize(target)

  const bind = useWheel(({ velocity, movement: [, my] }) => {
    var maxScrollPosition = contentHeight - windowHeight
    if (windowHeight > contentHeight)
      maxScrollPosition = windowHeight - contentHeight

    var wheelVelocity = isFirefox
      ? wheel_swipeVelocity_firefox
      : wheel_swipeVelocity

    const enoughWheelVelocity = velocity > wheelVelocity

    if (enoughWheelVelocity) {
      if (nextPageAllowed && my > 0 && scrollPosition.y === maxScrollPosition) {
        setCookie("userSwipedPage", "true", {
          path: "/",
          expires: NewCookieExpiryDate(21),
        })
        navigateTo.current = currentScreen + 1
        animTo.start("next")
      }

      if (previousPageAllowed && my < 0 && scrollPosition.y === 0) {
        setCookie("userSwipedPage", "true", {
          path: "/",
          expires: NewCookieExpiryDate(21),
        })
        navigateTo.current = currentScreen - 1
        animTo.start("previous")
      }
    }
  })

  const variants = {
    show: {},
    defPosition: {
      y: 0,
    },
    previous: {
      y: windowHeight > contentHeight ? windowHeight : contentHeight,
    },
    next: {
      y: windowHeight > contentHeight ? -windowHeight : -contentHeight,
    },
  }

  useEffect(() => {
    if (size) setContentHeight(Math.round(size.height))
  }, [size])

  useEffect(() => {
    if (cookies.userSwipedPage) setShowCorner(false)
    if (isMobile) console.log("Mobile Detected")
  }, [cookies])

  useEffect(() => {
    emitter.on("pageChange", (event) => {
      navigateTo.current = event.data
      if (event.data < event.currentScreen) {
        animTo.start("previous")
      }
      if (event.data > event.currentScreen) {
        animTo.start("next")
      }
    })
  }, [emitter, animTo])

  useEffect(() => {
    // When windowHeight is triggered, we set our viewPortHeight state so that it
    // is transferred to the wrapper element that needs a state to update immediately.
    Logger("Setting viewPortHeight.")
    y.set(0)
    setViewPortHeight(windowHeight)
  }, [windowHeight, y])

  useEffect(() => {
    if (contentHeight > viewPortHeight) {
      if (!isMobile) {
        setAllowScrollBars(true)
      }
      const topDragCon = (contentHeight - viewPortHeight) * -1
      setDragContraint(topDragCon)
      y.set(0)
    } else {
      if (!isMobile) {
        setAllowScrollBars(false)
      }
      setDragContraint(0)
      y.set(0)
    }
  }, [contentHeight, viewPortHeight, y])

  return (
    <Background
      onMouseMove={function (event) {
        emitter.emit("mouseX", { data: event.clientX })
        emitter.emit("mouseY", { data: event.clientY })
      }}
      ref={ref}
      style={{
        backgroundColor: screenSwipeBackgroundColor,
        height: viewPortHeight,
        overflowY: allowScrollBars ? "auto" : "hidden",
      }}
    >
      <Wrapper
        ref={target}
        {...bind()}
        drag={isMobile ? "y" : ""}
        dragElastic={0.825}
        variants={variants}
        animate={animTo}
        dragConstraints={{ bottom: 0, top: dragContraint }}
        transition={{
          y: { type: "spring", stiffness: 300, damping: 30 },
        }}
        onDragEnd={(e, { velocity }) => {
          const upperThresh = windowHeight * 0.05
          const lowerThresh = -contentHeight + windowHeight * 0.95

          if (!nextPageAllowed) {
          } else {
            if (velocity.y < -800 && y.get() < lowerThresh) {
              setCookie("userSwipedPage", "true", {
                path: "/",
                expires: NewCookieExpiryDate(21),
              })
              navigateTo.current = currentScreen + 1
              animTo.start("next")
            }
          }

          if (!previousPageAllowed) {
          } else {
            if (velocity.y > 800 && y.get() > upperThresh) {
              setCookie("userSwipedPage", "true", {
                path: "/",
                expires: NewCookieExpiryDate(21),
              })
              navigateTo.current = currentScreen - 1
              animTo.start("previous")
            }
          }
        }}
        onAnimationComplete={() => {
          Logger("Trying to navigate to: " + navigateTo.current)
          if (navigateTo.current !== -100)
            navigate(indexScreenUrls[navigateTo.current], {
              state: {
                showFooter: false,
                thisPage: navigateTo.current,
              },
              replace: false,
            })
        }}
        style={{
          y: y,
          minHeight: viewPortHeight,
          backgroundColor: screenBackgroundColor,
        }}
      >
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <ContentMargin
          style={{ height: showFooter ? `max(${uprContentMarg}px)` : "50px" }}
        />

        <ContentWrapper>
          <Content />
        </ContentWrapper>

        <ContentMargin
          style={{
            height: showFooter ? `max(${lwrContentMarg}px)` : "max(200px)",
          }}
        >
          <BreadCrumbs current={currentScreen} />
          {!showFooter && showCorner && (
            <CornerWrapper>
              <Corner />
            </CornerWrapper>
          )}
        </ContentMargin>
        <FooterWrapper style={{ backgroundColor: screenBackgroundColor }}>
          {showFooter && <Footer />}
        </FooterWrapper>
      </Wrapper>
    </Background>
  )
}

const Background = styled.div`
  /* Desktop must have overflow-y set to auto so that we can see and
 use the scroll bars.  On mobile, we need to set to hidden.  This
 is handled in javascript */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`

const Wrapper = styled(motion.div)`
  /* Touch action is set to none so that it disables dragging on android
  devices.  Unfortunately, this does not work with iOs, so we have to
  use overflow hidden/auto tricks. */
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: beige;
  touch-action: none;
  overflow: hidden;
`

const ContentMargin = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderWrapper = styled.div`
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CornerWrapper = styled.div`
  position: absolute;
  /* z-index: 1000; */
  overflow: hidden;
  height: 300px;
  width: 300px;
  right: 0;
  transform: translateY(-50px);
`

const FooterWrapper = styled.div`
  /* If there is no footer, use height 0px.  Use footer height otherwise.*/
  /* This ensures that calculations are made with or without footer present. */
  /* You probably still need to turn Footer on/off with conditional switch */
  /* height: 100px; */
`
