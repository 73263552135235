import React from "react"
import styled from "styled-components"
import { motion, useSpring } from "framer-motion"
import { color_secondary } from "_data/global_colors"

const circleVariant = {
  open: {
    scale: 6,
    translateX: -30,
    translateY: 100,
    transition: {
      delay: 0.1,
    },
  },
  closed: {
    scale: 1,
    translateX: 0,
    translateY: 0,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 30,
      mass: 1,
      restDelta: 0.001,
      restSpeed: 0.001,
      delay: 0.1,
    },
  },
}

const questionMarkVariant = {
  open: {
    opacity: 0,
    transition: {
      duration: 0.05,
    },
  },
  closed: {
    opacity: 1,
    transition: {
      delay: 0.25,
      duration: 0.15,
    },
  },
}

const Help = (props) => {
  const onHover_sc = useSpring(1.02, {
    type: "spring",
    stiffness: 300,
    damping: 20,
    mass: 2,
    restDelta: 0.001,
    restSpeed: 0.0001,
  })

  return (
    <Wrapper
      transition={{
        delay: 1.1,
        duration: 1.65,
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <OnTap
        variants={circleVariant}
        initial={false}
        whileTap={{ scale: 0.8 }}
        transition={{
          type: "spring",
          stiffness: 300,
          damping: 20,
          mass: 2,
          restDelta: 0.001,
          restSpeed: 0.001,
        }}
        animate={props.help_isActive ? "open" : "closed"}
      >
        <OnHover
          style={{
            perspective: "500px",
            scale: onHover_sc,
          }}
          transition={{
            type: "spring",
            stiffness: 530,
            damping: 60,
            mass: 6,
            restDelta: 0.01,
            restSpeed: 0.01,
          }}
          initial={{ rotate: 5 }}
          whileHover={{
            rotate: 10,
          }}
          onHoverStart={() => {
            if (!props.help_isActive) onHover_sc.set(1.14)
          }}
          onHoverEnd={() => {
            if (!props.help_isActive) onHover_sc.set(1)
          }}
        >
          <QuestionMark
            variants={questionMarkVariant}
            initial={false}
            animate={props.help_isActive ? "open" : "closed"}
          >
            ?
          </QuestionMark>
        </OnHover>
      </OnTap>
    </Wrapper>
  )
}

const Wrapper = styled(motion.div)`
  position: absolute;
  top: -15px;
  right: 13px;
  height: 40px;
  width: 40px;
  z-index: 20;
`

const OnTap = styled(motion.div)`
  height: 100%;
  width: 100%;
`

const OnHover = styled(motion.div)`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  box-shadow: 2px 2px 8px 3px rgba(66, 34, 62, 0.6);
  cursor: pointer;
  background-color: ${color_secondary};
`

const QuestionMark = styled(motion.div)`
  color: white;
  font-family: montserrat;
  user-select: none;
  font-weight: 800;
  font-size: 26px;
`

export default Help
