import React from "react"
import styled from "styled-components"
import { isMobile } from "react-device-detect"
import { motion } from "framer-motion"
import { color_primary, color_paragraphHighlite } from "_data/global_colors"

const closeVariant = {
  open: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.05,
      type: "spring",
      stiffness: 300,
      damping: 20,
      mass: 2,
      restDelta: 0.001,
      restSpeed: 0.0001,
    },
  },
  closed: {
    opacity: 0,
    scale: 0.5,
    transition: {
      delay: 0.08,
      type: "spring",
      stiffness: 300,
      damping: 20,
      mass: 2,
      restDelta: 0.001,
      restSpeed: 0.0001,
    },
  },
}

const Close = (props) => {
  return (
    <Position
      initial={{ opacity: 0, scale: 0.5 }}
      variants={closeVariant}
      animate={props.help_isActive ? "open" : "closed"}
      style={{
        bottom: isMobile ? "125px" : "140px",
      }}
    >
      <OnHover
        style={{
          perspective: "500px",
        }}
        transition={{
          type: "spring",
          stiffness: 300,
          damping: 20,
          mass: 2,
          restDelta: 0.001,
          restSpeed: 0.0001,
        }}
        whileTap={{ scale: 0.9 }}
        initial={{ rotate: 5 }}
        whileHover={{
          scale: 1.15,
          rotate: 10,
        }}
      >
        <Icon>X</Icon>
      </OnHover>
    </Position>
  )
}

const Position = styled(motion.div)`
  position: absolute;
  bottom: 125px;
  right: 15px;
  height: 50px;
  width: 50px;
  z-index: 30;
`

const OnHover = styled(motion.div)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 200;
  border-radius: 100%;
  box-shadow: 2px 2px 8px 3px rgba(66, 34, 62, 0.16);
  cursor: pointer;
  background-color: ${color_primary};
`

const Icon = styled(motion.div)`
  color: ${color_paragraphHighlite};
  color: white;
  user-select: none;
  font-family: montserrat;
  font-weight: 700;
  font-size: 20px;
`

export default Close
