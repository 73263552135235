import React, { useState, useEffect } from "react"
import { color_primary } from "_data/global_colors"
import { motion } from "framer-motion"
import Close from "./close"
import Help from "./help"
import Mouse from "./mouse"
// import Finger from "./finger"
import { isMobile } from "react-device-detect"
import Instructions from "./instructions"
import styled from "styled-components"

const Corner = () => {
  const [help_isActive, help_isActive_set] = useState(false)
  const [showFinger, setShowFinger] = useState(false)

  useEffect(() => {
    if (isMobile) setShowFinger(true)
    else setShowFinger(false)
  }, [])

  if (isMobile) return null

  return (
    <Wrapper>
      <Wedge
        transition={{
          delay: 1,
          duration: 1,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <Button onClick={() => help_isActive_set(true)}>
          <Help help_isActive={help_isActive} />
        </Button>
        <Button onClick={() => help_isActive_set(false)}>
          <Close help_isActive={help_isActive} />
        </Button>
      </Wedge>
      <Instructions help_isActive={help_isActive} />
      {/* {showFinger && <Finger help_isActive={help_isActive} />} */}
      {!showFinger && <Mouse help_isActive={help_isActive} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  width: 150px;
  min-width: 150px;
  height: 135px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 500px) {
    display: none;
  }
`

const Wedge = styled(motion.div)`
  height: 100%;
  width: 100%;
  border-radius: 100% 0 0 0;
  box-shadow: 0px 0px 8px 3px rgba(21, 70, 136, 0.25);
  background-color: ${color_primary};
`

const Button = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`


export default Corner
